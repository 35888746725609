import secureLocalStorage from "react-secure-storage";
import { ORGANIZATION_ID } from './PMotionGlobals';

export enum StorageKey {
	User = 'user',
	Assessment = 'assessment',
	LoginUserName = 'login',
	DataTable = 'data-table'
}

const StorageKeyPrefix = `${ORGANIZATION_ID}_`

type StorageReturnType = any | null;

export const getSession = (key:string):StorageReturnType => {
	const stored = sessionStorage.getItem(StorageKeyPrefix + key);
	return stored ? JSON.parse(stored) : null;
}

export const setSession = (key:string, obj:any) => {
	sessionStorage.setItem(StorageKeyPrefix + key, JSON.stringify(obj));
}

export const deleteSession = (key:string) => {
	sessionStorage.removeItem(StorageKeyPrefix + key);
}

export const getLocal = (key:string):StorageReturnType => {
	const stored = secureLocalStorage.getItem(StorageKeyPrefix + key) as string;
	return stored ? JSON.parse(stored) : null;
}

export const setLocal = (key:string, obj:any) => {
	secureLocalStorage.setItem(StorageKeyPrefix + key, JSON.stringify(obj));
}

export const deleteLocal = (key:string) => {
	secureLocalStorage.removeItem(StorageKeyPrefix + key);
}
