import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

import App from './App';
import * as serviceWorker from './serviceWorker';

import {store, persistor} from './redux/store';

// delete insecure data from localStorage predating the migration to secureLocalStorage
function removeFromLocalStorage(pattern: string): void {
    const regex = new RegExp(pattern);

    Object.keys(localStorage).forEach((key: string) => {
        if (regex.test(key)) {
            localStorage.removeItem(key);
        }
    });
}

removeFromLocalStorage("_login$");
removeFromLocalStorage("_user$");
removeFromLocalStorage("^persist:");


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <PersistGate persistor={persistor}>
                <App />
            </PersistGate>
        </BrowserRouter>
    </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
