import secureLocalStorage from "react-secure-storage";
import { ORGANIZATION_ID } from '../utils/PMotionGlobals';
import assessmentReducer from './assessment/assessment.reducer';
import assessmentsReducer from './assessments/assessments.reducer';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { combineReducers } from 'redux';
import contentReducer from './content/content.reducer';
import correctivesReducer from './correctives/correctives.reducer';
import dashboardReducer from './dashboard/dashboard.reducer';
import exerciseReducer from './exercise/exercise.reducer';
import messageReducer from './message/message.reducer';
import orgReducer from './org/org.reducer';
import { orgStateTransform, reportStateTransform } from './root.transforms';
import { persistReducer } from 'redux-persist';
import playerReducer from './player/player.reducer';
import statusReducer from './status/status.reducer';
import storageSession from 'redux-persist/lib/storage/session';
import userReducer from './user/user.reducer';
import reportReducer from './report/report.reducer';

const rootPersistConfig = {
    key: ORGANIZATION_ID,
    storage: storageSession,
    whitelist: ['exercise', 'message', 'dashboard'],
    blacklist: ['org', 'user', 'status', 'assessment', 'player', 'correctives', 'assessments', 'content'],
    stateReconciler: autoMergeLevel2
};

// Secure storage wrapper for redux-persist
const secureStorage = {
    getItem: (key: string): Promise<string | null> => {
      return new Promise((resolve, reject) => {
        try {
          const value = secureLocalStorage.getItem(key) as string | null;
          resolve(value);
        } catch (error) {
          console.error("Error getting persisted state:", error);
          reject(error);
        }
      });
    },
  
    setItem: (key: string, value: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        try {
          secureLocalStorage.setItem(key, value);
          resolve();
        } catch (error) {
          console.error("Error setting persisted state:", error);
          reject(error);
        }
      });
    },
  
    removeItem: (key: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        try {
          secureLocalStorage.removeItem(key);
          resolve();
        } catch (error) {
          console.error("Error removing persisted state:", error);
          reject(error);
        }
      });
    },
  };
  
const orgPersistConfig = {
    key: 'org',
    storage: secureStorage,
    stateReconciler: autoMergeLevel2,
    transforms: [orgStateTransform]
};

const dashboardPersistConfig = {
    key: 'dashboard',
    storage: secureStorage,
    whitelist: ['rowsPerPage'],
    stateReconciler: autoMergeLevel2
};

const assessmentPersistConfig = {
    key: 'assessment',
    storage: storageSession,
    whitelist: ['orgMembers', 'currentOrgMember'],
    stateReconciler: autoMergeLevel2
};

const userPersistConfig = {
    key: 'user',
    storage: secureStorage,
    whitelist: ['lastLoginInfo', 'usePlayerTimezone', 'showExerciseFinishedTime'],
    stateReconciler: autoMergeLevel2
};

const reportsPersistConfig = {
    key: 'report',
    storage: secureStorage,
    whitelist: ['rowsPerPage', 'columnsMap', 'selectedOrgIds'],
    stateReconciler: autoMergeLevel2,
    transforms: [reportStateTransform]
};


const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    player: playerReducer,
    org: persistReducer(orgPersistConfig, orgReducer),
    assessment: persistReducer(assessmentPersistConfig, assessmentReducer),
    correctives: correctivesReducer,
    assessments: assessmentsReducer,
    exercise: exerciseReducer,
    status: statusReducer,
    dashboard: persistReducer(dashboardPersistConfig, dashboardReducer),
    message: messageReducer,
    content: contentReducer,
    report: persistReducer(reportsPersistConfig, reportReducer)
});

// @ts-ignore
export default persistReducer(rootPersistConfig, rootReducer);